<template>
  <b-container fluid>
      <b-row v-if="!loader">
        <b-col lg="12" sm="12">
          <b-overlay :show="loading">
            <template>
                <b-row>
                  <b-col>
                      <list-report-head :base-url="seedFertilizerServiceBaseUrl" :uri="reportHeadingList" :org-id="seedOrgId">
                          {{ $t('seedsSeeds.StockManageDetails') }}
                      </list-report-head>
                  </b-col>
                </b-row>
            </template>
            <iq-card>
              <template v-slot:body>
                <b-row>
                    <b-col lg="12" sm="12">
                        <b-table striped small bordered hover :items="columns"  thead-class="hidden_header"></b-table>
                    </b-col>
                    <b-col lg="12" sm="12">
                        <b-table-simple striped bordered small class="mt-2">
                            <b-tr>
                                <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                                <b-th>{{ $t('seedsConfig.variety') }}</b-th>
                                <b-th>{{ $t('globalTrans.quantity') }}</b-th>
                            </b-tr>
                            <b-tr v-for="(res,index) in  data.stock_info_details" :key="index">
                                <b-td>{{ $n(index+1) }}</b-td>
                                <b-td>{{ ($i18n.locale==='bn') ? res.variety.variety_name_bn  :  res.variety.variety_name }}</b-td>
                                <b-td>{{$n(res.quantity, { useGrouping: false })}}</b-td>
                            </b-tr>
                        </b-table-simple>
                    </b-col>
                </b-row>
              </template>
              </iq-card>
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { dateFormat } from '@/Utils/fliter'
import ExportPdf from './export_pdf_details'
import ListReportHead from '@/components/custom/ListReportHeadSeed.vue'
import { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList } from '../../api/routes'

export default {
  components: {
      ListReportHead
  },
  props: ['id'],
  created () {
    this.seedOrgId = 1
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.seedOrgId = this.$store.state.Auth.authUser.org_id
    }
    if (this.id) {
      const tmp = this.getStockManageData()
      this.data = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
     seedOrgId: 0,
     saveBtnName: this.$t('globalTrans.view'),
     loading: false,
      cropNameList: [],
      loader: false,
      search: {
        crop_name_id: 0
      },
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      reportHeadingList: reportHeadingList
    }
  },
  computed: {
      columns () {
          if (this.$i18n.locale === 'bn') {
              return [
                  { key: this.$t('org_pro.organization'), val: this.data.org_name_bn, key1: this.$t('seedsConfig.seedClass'), val1: this.data.class_name_bn },
                  { key: this.$t('seedsConfig.measurementUnit'), val: this.data.measurementUnit_name_bn, key1: this.$t('seedsConfig.cropType'), val1: this.data.crop_type_name_bn },
                  { key: this.$t('seedsConfig.cropName'), val: this.data.crop_name_name_bn, key1: this.$t('seedsSeeds.stock_date'), val1: dateFormat(this.data.stock_date) },
                  { key: this.$t('seedsConfig.carryover'), val: this.data.carryOver_name_bn, key1: this.$t('seedsConfig.productionSeason'), val1: (this.data.production_season_name_bn) },
                  { key: this.$t('seedsSeeds.office_type'), val: this.data.office_type_bn, key1: this.$t('seedsSeeds.office_name'), val1: (this.data.spc_office_name_bn) },
                  { key: this.$t('seedsConfig.production_source_type'), val: this.data.source_type_name_bn, key1: this.$t('seedsSeeds.production_source'), val1: (this.data.source_name_bn) }
              ]
          } else {
              return [
                  { key: this.$t('org_pro.organization'), val: this.data.org_name, key1: this.$t('seedsConfig.seedClass'), val1: this.data.class_name },
                  { key: this.$t('seedsConfig.measurementUnit'), val: this.data.measurementUnit_name, key1: this.$t('seedsConfig.cropType'), val1: this.data.crop_type_name },
                  { key: this.$t('seedsConfig.cropName'), val: this.data.crop_name_name, key1: this.$t('seedsSeeds.stock_date'), val1: (this.data.stock_date) },
                  { key: this.$t('seedsConfig.carryover'), val: this.data.carryOver_name, key1: this.$t('seedsConfig.productionSeason'), val1: (this.data.production_season_name) },
                  { key: this.$t('seedsSeeds.office_type'), val: this.data.office_type, key1: this.$t('seedsSeeds.office_name'), val1: (this.data.spc_office_name) },
                  { key: this.$t('seedsConfig.production_source_type'), val: this.data.source_type_name, key1: this.$t('seedsSeeds.production_source'), val1: (this.data.source_name) }
              ]
          }
      }
  },
  methods: {
    getStockManageData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getCropNameList (croptypeID) {
      const cropNameList = this.$store.state.SeedsFertilizer.commonObj.CropNameList.filter(item => item.status === 1)
      if (croptypeID) {
        return cropNameList.filter(cropName => cropName.crop_type_id === croptypeID)
      }
      return cropNameList
    },
    pdfExport () {
      const reportTitle = this.$t('seedsSeeds.stock_manage') + ' ' + this.$t('globalTrans.details')
      ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/seeds/config/report-heading/detail', this.seedOrgId, reportTitle, this.data, this)
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
