<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:body>
                <b-row>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <ValidationProvider name="Fiscal Year" vid="fiscal_year_id">
                            <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="fiscal_year_id"
                            >
                            <template v-slot:label>
                            {{$t('warehouse_config.fiscal_year')}}
                            </template>
                            <b-form-select
                            plain
                            v-model="search.fiscal_year_id"
                            :options="fiscalList"
                            id="fiscal_year_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col  md="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('seedsSeeds.cropType')"
                            label-for="cropType"
                        >
                            <v-select name="croptypeID"
                                v-model="search.crop_type_id"
                                label="text"
                                :options= CropTypeList
                                :placeholder="$t('globalTrans.select')"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                       <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('seedsConfig.cropName')"
                        label-for="email"
                        >
                        <v-select name="croptypeID"
                        v-model="search.crop_name_id"
                        label="text"
                        :options= cropNameList
                        :placeholder="$t('globalTrans.select')"
                        />
                        </b-form-group>
                    </b-col>
                    <b-col  md="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('seedsSeeds.seedsClass')"
                            label-for="seedClass"
                        >
                            <v-select name="croptypeID"
                                v-model="search.seed_class_id"
                                label="text"
                                :options= seedClassList
                                :placeholder="$t('globalTrans.select')"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col></b-col>
                    <b-col cols="12">
                        <b-button class="float-right" type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('seedsSeeds.stock_List')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <!-- <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
                             {{  $t('globalTrans.add_new') }}
                        </b-button> -->
                        <b-button class="btn_add_new" size="sm" v-b-modal.modal-4 @click="resetId">
                            <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" bordered striped hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(commodity_group_name)="data">
                                            <span class="capitalize">{{ data.item.commodity_group_name }}</span>
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                        <!-- <b-button v-b-modal.modal-5 variant=" iq-bg-warning mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="fas fa-eye"></i></b-button>
                                        <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button> -->
                                        <a href="javascript:" class="btn_table_action table_action_view" title="View" v-b-modal.modal-5 size="sm" @click="edit(data.item)">
                                                <i class="fas fa-eye"></i>
                                        </a>
                                        <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                                                <i class="ri-ball-pen-fill"></i>
                                        </a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-5" size="lg" :title="this.$t('globalTrans.view')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <!-- <b-button @click="pdfExport()" class="ml-4 btn-success water-test-pdf-button">
                 {{  $t('globalTrans.print') }}
            </b-button> -->
             <b-button @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
              {{  $t('globalTrans.export_pdf') }}
            </b-button>
            <Details :id="editItemId" :key="editItemId"  ref="details"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import Details from './Details'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { stockManageList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form,
        Details
    },
    data () {
        return {
            search: {
                fiscal_year_id: 0,
                crop_type_id: '',
                crop_name_id: '',
                seed_class_id: '',
                org_id: this.$store.state.dataFilters.orgId,
                spc_office_id: this.$store.state.dataFilters.officeId
            },
             cropNameList: [],
             item: ''
        }
    },
    computed: {
        fiscalList: function () {
            const fiscalyearData = this.$store.state.commonObj.fiscalYearList
            return fiscalyearData.map(item => {
                if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
                } else {
                return { value: item.value, text: item.text_en }
                }
            })
        },
        CropTypeList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.CropTypeList.filter(item => item.status === 1)
        },
        seedClassList: function () {
         return this.$store.state.SeedsFertilizer.commonObj.seedClassList.filter(item => item.status === 1)
        },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('seedsSeeds.stock_manage') + ' ' + this.$t('globalTrans.entry') : this.$t('seedsSeeds.stock_manage_modify')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('warehouse_config.fiscal_year'), class: 'text-center' },
                { label: this.$t('seedsConfig.productionSeason'), class: 'text-center' },
                { label: this.$t('seedsSeeds.organization'), class: 'text-center' },
                { label: this.$t('seedsSeeds.office_type'), class: 'text-center' },
                { label: this.$t('seedsSeeds.office'), class: 'text-center' },
                { label: this.$t('seedsConfig.cropType'), class: 'text-center' },
                { label: this.$t('seedsConfig.cropName'), class: 'text-center' },
                { label: this.$t('seedsSeeds.seedsClass'), class: 'text-left' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                    { key: 'index' },
                    { key: 'fiscal_year_bn' },
                    { key: 'production_season_name_bn' },
                    { key: 'org_name_bn' },
                    { key: 'office_type_bn' },
                    { key: 'spc_office_name_bn' },
                    { key: 'crop_type_name_bn' },
                    { key: 'crop_name_name_bn' },
                    { key: 'class_name_bn' },
                    { key: 'action' }
                ]
            } else {
                keys = [
                    { key: 'index' },
                    { key: 'fiscal_year' },
                    { key: 'production_season_name' },
                    { key: 'org_name' },
                    { key: 'office_type' },
                    { key: 'spc_office_name' },
                    { key: 'crop_type_name' },
                    { key: 'crop_name_name' },
                    { key: 'class_name' },
                    { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        },
        currentFiscalYearId: function () {
            return this.$store.state.SeedsFertilizer.currentFiscalYearId
        }
    },
    async created () {
        this.search = Object.assign({}, this.search, { fiscal_year_id: this.currentFiscalYearId })
        this.loadData()
    },
    mounted () {
        core.index()
    },
    watch: {
        'search.crop_type_id': function (newVal, oldVal) {
         this.cropNameList = this.getCropNameList(newVal)
        }
     },
    methods: {
        dataChange () {
            this.loadData()
        },        
        getVarityName (varityId) {
          const item = this.$store.state.SeedsFertilizer.commonObj.VarietyList.find(listitem => listitem.value === varityId)
          if (this.$i18n.locale === 'en') {
            return item.text
          } else {
            return item.text_bn
          }
        },
        getCropNameList (croptypeID) {
            const cropNameList = this.$store.state.SeedsFertilizer.commonObj.CropNameList.filter(item => item.status === 1)
            if (croptypeID) {
                return cropNameList.filter(cropName => cropName.crop_type_id === croptypeID.value)
            }
            return cropNameList
        },
        async searchData () {
            this.loadData()
        },
        loadData () {
            const params = Object.assign({}, this.search, {
                 fiscal_year_id: this.search.fiscal_year_id.value,
                 crop_type_id: this.search.crop_type_id.value,
                 crop_name_id: this.search.crop_name_id.value,
                 seed_class_id: this.search.seed_class_id.value,
                 page: this.pagination.currentPage, per_page: this.pagination.perPage })
                this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
                RestApi.getData(seedFertilizerServiceBaseUrl, stockManageList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', this.relationalData(response.data))
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        relationalData (data) {
            const recommendationData = data.data.map(item => {
                const fiscalyearData = this.$store.state.commonObj.fiscalYearList.find(listitem => listitem.value === item.fiscal_year_id && listitem.status === 0)
                const productionSeasonData = this.$store.state.SeedsFertilizer.commonObj.productionSeasonList.find(listitem => listitem.value === item.production_season_id && listitem.status === 1)
                const orgData = this.$store.state.orgList.find(listitem => listitem.value === item.org_id && listitem.status === 0)
                const officeTypeData = this.$store.state.commonObj.officeTypeList.find(listitem => listitem.value === item.office_type_id && listitem.status === 0)
                const spcOfficeData = this.$store.state.commonObj.officeList.find(listitem => listitem.value === item.spc_office_id && listitem.status === 0)
                const cropTypeData = this.$store.state.SeedsFertilizer.commonObj.CropTypeList.find(listitem => listitem.value === item.crop_type_id && listitem.status === 1)
                const cropNameData = this.$store.state.SeedsFertilizer.commonObj.CropNameList.find(listitem => listitem.value === item.crop_name_id && listitem.status === 1)
                const seedsClasData = this.$store.state.SeedsFertilizer.commonObj.seedClassList.find(listitem => listitem.value === item.seed_class_id && listitem.status === 1)
                const measurementUnitData = this.$store.state.SeedsFertilizer.commonObj.unitList.find(listitem => listitem.value === item.measurement_unit_id)
                const carryoverListData = this.$store.state.SeedsFertilizer.commonObj.carryoverList.find(carryover => carryover.value === item.carryovers_id && carryover.status === 1)
                const productionSourceType = this.$store.state.SeedsFertilizer.commonObj.seedProductionSourceTypeList.find(sourceType => sourceType.value === item.source_type_id && sourceType.status === 1)
                const productionSource = this.$store.state.SeedsFertilizer.commonObj.productionSourceList.find(source => source.value === item.production_source_id && source.status === 1)
                const tmpBindingData = {
                fiscal_year: fiscalyearData !== undefined ? fiscalyearData.text_en : '',
                fiscal_year_bn: fiscalyearData !== undefined ? fiscalyearData.text_bn : '',
                production_season_name: productionSeasonData !== undefined ? productionSeasonData.text_en : '',
                production_season_name_bn: productionSeasonData !== undefined ? productionSeasonData.text_bn : '',
                org_name: orgData !== undefined ? orgData.text_en : '',
                org_name_bn: orgData !== undefined ? orgData.text_bn : '',
                spc_office_name: spcOfficeData !== undefined ? spcOfficeData.text_en : '',
                spc_office_name_bn: spcOfficeData !== undefined ? spcOfficeData.text_bn : '',
                office_type: officeTypeData !== undefined ? officeTypeData.text_en : '',
                office_type_bn: officeTypeData !== undefined ? officeTypeData.text_bn : '',
                crop_type_name: cropTypeData !== undefined ? cropTypeData.text_en : '',
                crop_type_name_bn: cropTypeData !== undefined ? cropTypeData.text_bn : '',
                crop_name_name: cropNameData !== undefined ? cropNameData.text_en : '',
                crop_name_name_bn: cropNameData !== undefined ? cropNameData.text_bn : '',
                class_name: seedsClasData !== undefined ? seedsClasData.text_en : '',
                class_name_bn: seedsClasData !== undefined ? seedsClasData.text_bn : '',
                measurementUnit_name: measurementUnitData !== undefined ? measurementUnitData.text_en : '',
                measurementUnit_name_bn: measurementUnitData !== undefined ? measurementUnitData.text_bn : '',
                carryOver_name: carryoverListData !== undefined ? carryoverListData.text_en : '',
                carryOver_name_bn: carryoverListData !== undefined ? carryoverListData.text_bn : '',
                source_type_name: productionSourceType !== undefined ? productionSourceType.text_en : '',
                source_type_name_bn: productionSourceType !== undefined ? productionSourceType.text_bn : '',
                source_name: productionSource !== undefined ? productionSource.text_en : '',
                source_name_bn: productionSource !== undefined ? productionSource.text_bn : ''
                }
            return Object.assign({}, item, tmpBindingData)
            })
            return recommendationData
        },
        pdfExport () {
            this.$refs.details.pdfExport()
        }
    }
}
</script>
