<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <b-overlay :show="loading">
          <iq-card>
            <template v-slot:body>
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                      <b-row>
                          <b-col lg="6"  sm="12">
                            <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="org_id"
                                      slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                      {{$t('warehouse_config.organization')}} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                      plain
                                      v-model="stockManage.org_id"
                                      :options="orgList"
                                      id="org_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                          <b-col lg="6"  sm="12">
                              <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">>
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="fiscal_year_id"
                                      slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                      {{$t('seedsSeeds.fiscal_year')}} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                      plain
                                      v-model="stockManage.fiscal_year_id"
                                      :options="fiscalList"
                                      id="fiscal_year_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                          <b-col lg="6"  sm="12">
                            <ValidationProvider name="Production Season" vid="production_season_id" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="production_season_id"
                                      slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                      {{$t('seedsSeeds.production_season')}} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                      plain
                                      v-model="stockManage.production_season_id"
                                      :options="productionSeasonList"
                                      id="production_season_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                          <b-col>
                             <ValidationProvider name="Production Source Type" vid="source_type_id" rules="required|min_value:1">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="source_type_id"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('seedsConfig.production_source_type')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="stockManage.source_type_id"
                                  :options="productionSourceTypeList"
                                  id="source_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6"  sm="12">
                              <ValidationProvider name="Production Source" vid="production_source_id" rules="required|min_value:1">>
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="production_source_id"
                                      slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                      {{$t('seedsSeeds.production_source')}} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                      plain
                                      v-model="stockManage.production_source_id"
                                      :options="productionSourceList"
                                      id="production_source_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                          <b-col lg="6"  sm="12">
                            <ValidationProvider name="Carryover/Fresh" vid="carryovers_id" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="carryovers_id"
                                      slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                      {{$t('seedsSeeds.carryover')}}<span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                      plain
                                      v-model="stockManage.carryovers_id"
                                      :options="carryoverList"
                                      id="carryovers_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                          <b-col lg="6"  sm="12">
                              <ValidationProvider name="Program Type" vid="program_type_id">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="program_type_id"
                                      slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                        {{$t('seedsSeeds.program_type')}}
                                      </template>
                                      <b-form-select
                                      plain
                                      v-model="stockManage.program_type_id"
                                      :options="programTypeList"
                                      id="program_type_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                          <b-col lg="6"  sm="12">
                            <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="office_type_id"
                                      slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                      {{$t('seedsSeeds.office_type')}} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                      plain
                                      v-model="stockManage.office_type_id"
                                      :options="officeTypeList"
                                      id="office_type_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                          <b-col lg="6"  sm="12">
                            <ValidationProvider name="Office" vid="spc_office_id" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="spc_office_id"
                                      slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                      {{$t('seedsSeeds.office_name')}} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                      plain
                                      v-model="stockManage.spc_office_id"
                                      :options="officeList"
                                      id="spc_office_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                          <b-col lg="6"  sm="12">
                            <ValidationProvider name="Seeds Class" vid="seed_class_id" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="seed_class_id"
                                      slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                      {{$t('seedsSeeds.seedsClass')}} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                      plain
                                      v-model="stockManage.seed_class_id"
                                      :options="seedClassList"
                                      id="seed_class_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Crop Type" vid="crop_type_id"  rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="crop_type_id"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('seedsSeeds.cropType')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="stockManage.crop_type_id"
                                  :options="cropTypeList"
                                  id="crop_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Crop Name" vid="crop_name_id"  rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="crop_name_id"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('seedsSeeds.cropName')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="stockManage.crop_name_id"
                                  :options="cropNameList"
                                  id="crop_name_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  @input="getVarietyInfo(stockManage.crop_name_id)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6"  sm="12">
                              <ValidationProvider name="Unit Name" vid="measurement_unit_id" rules="required|min_value:1">>
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="measurement_unit_id"
                                      slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                      {{$t('seedsSeeds.unitName')}} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                      plain
                                      v-model="stockManage.measurement_unit_id"
                                      :options="unitNameList"
                                      id="measurement_unit_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      disabled
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                          <b-col lg="6" md="6">
                            <ValidationProvider name="Stock Date" vid="stock_date" rules="required">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="stock_date"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('seedsSeeds.stock_date')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input class="form-control"
                                  v-model="stockManage.stock_date"
                                  placeholder="Select Date"
                                  id="date"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <div class="col-12"></div>
                        <div class="d-flex justify-content-center" v-if="loader" style="margin: auto;">
                            <div class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <table class="table table-bordered" v-else>
                          <tr>
                            <th style="width:10%">{{$t('globalTrans.sl_no')}}</th>
                            <th style="width:50%">{{$t('seedsConfig.variety')}}</th>
                            <th>{{$t('seedsSeeds.quantity')}}</th>
                          </tr>
                          <slot v-if="stockManage.stock_info_details.length>0">
                            <tr :key="index" v-for="(res,index) in stockManage.stock_info_details">
                              <td>{{index + 1}}</td>
                              <td>
                                <slot v-if="res.variety">
                                  {{ ($i18n.locale==='bn') ? res.variety.variety_name_bn  :  res.variety.variety_name }}
                                </slot>
                                <slot v-else>
                                  {{ ($i18n.locale==='bn') ? res.variety_name_bn  :  res.variety_name }}
                                </slot>
                              </td>
                              <td>
                                <b-form-input
                                  v-model="res.quantity"
                                  class="mb-2 mr-sm-2 mb-sm-0 form-control"
                                ></b-form-input>
                              </td>
                            </tr>
                          </slot>
                          <slot v-else>
                            <tr><td colspan="4"><h4 class="text-danger text-center">No Data Available</h4></td></tr>
                          </slot>
                      </table>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { stockManageStore, stockManageUpdate, varietiesNameWise } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.stockManage.org_id = this.$store.state.dataFilters.orgId
    this.stockManage.office_type_id = this.$store.state.dataFilters.officeTypeId
    this.stockManage = Object.assign({}, this.stockManage, { fiscal_year_id: this.currentFiscalYearId })
    if (this.id) {
      const tmp = this.getStockManageData()
      this.stockManage = tmp
    }
  },
  mounted () {
    flatpickr('#date', {})
    core.index()
    // this.stockManage.org_id = this.$store.state.dataFilters.orgId
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      stockManage: {
        id: '',
        org_id: 0,
        fiscal_year_id: 0,
        production_season_id: 0,
        production_source_id: 0,
        carryovers_id: 0,
        program_type_id: 0,
        office_type_id: 0,
        spc_office_id: this.$store.state.dataFilters.officeId,
        seed_class_id: 0,
        crop_type_id: 0,
        crop_name_id: 0,
        measurement_unit_id: 2,
        source_type_id: 0,
        stock_date: null,
        stock_info_details: []
      },
      cropNameList: [],
      varietyList: [],
      officeList: [],
      officeTypeList: [],
      productionSourceList: [],
      loader: false,
      loading: false,
      search: {
        crop_name_id: 0
      }
    }
  },
  watch: {
    'stockManage.crop_type_id': function (newVal, oldVal) {
      this.cropNameList = this.getCropNameList(newVal)
    },
    'stockManage.org_id': function (newVal, oldVal) {
      this.officeTypeList = this.getOfficeTypeList(newVal)
    },
    'stockManage.office_type_id': function (newVal, oldVal) {
     this.officeList = this.getOfficeList(newVal)
    },
    'stockManage.source_type_id': function (newVal, oldVal) {
     this.productionSourceList = this.getProductionSourceList(newVal)
    }
  },
  computed: {
    productionSourceTypeList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.seedProductionSourceTypeList.filter(item => item.status === 1)
    },
    orgList: function () {
      const tmpData = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      return tmpData.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text }
        }
      })
    },
    fiscalList: function () {
        const fiscalyearData = this.$store.state.commonObj.fiscalYearList
        return fiscalyearData.map(item => {
            if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
            } else {
            return { value: item.value, text: item.text_en }
            }
        })
    },
    productionSeasonList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.productionSeasonList.filter(item => item.status === 1)
    },
    seedClassList: function () {
       return this.$store.state.SeedsFertilizer.commonObj.seedClassList.filter(item => item.status === 1)
    },
    cropTypeList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.CropTypeList.filter(item => item.status === 1)
    },
    priceTypeList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.priceTypeList.filter(item => item.status === 1)
    },
    carryoverList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.carryoverList.filter(item => item.status === 1)
    },
    programTypeList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.programTypeList.filter(item => item.status === 1)
    },
    unitNameList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.unitList
    },
    currentFiscalYearId: function () {
      return this.$store.state.SeedsFertilizer.currentFiscalYearId
    }
  },
  methods: {
    customMizeSelect (data, id) {
      this.stockManage[id] = data
      if (id === 'crop_type_id') {
        this.stockManage.crop_name = []
        this.getCropNameList(this.stockManage.crop_type_id)
      }
    },
    getStockManageData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async createData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.stockManage.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${stockManageUpdate}/${this.id}`, this.stockManage)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, stockManageStore, this.stockManage)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        if (result.errors) {
          this.$refs.form.setErrors(result.errors)
        } else {
          this.$toast.error({
            title: 'Error',
            message: result.message,
            color: '#D6E09B'
          })
        }
         this.loading = false
      }
    },
    getCropNameList (croptypeID) {
      const cropNameList = this.$store.state.SeedsFertilizer.commonObj.CropNameList.filter(item => item.status === 1)
      if (croptypeID) {
        return cropNameList.filter(cropName => cropName.crop_type_id === croptypeID)
      }
      return cropNameList
    },
    getVarietyInfo (cropNameId) {
      this.loader = true
      this.search.crop_name_id = cropNameId
      RestApi.getData(seedFertilizerServiceBaseUrl, varietiesNameWise, this.search).then(response => {
        this.stockManage.stock_info_details = response.data.map(item => {
          const quantityData = {
              quantity: ''
          }
          return Object.assign({}, item, quantityData)
        })
        this.loader = false
      })
    },
    getVarietyName (Id) {
        const variety = this.$store.state.SeedsFertilizer.commonObj.VarietyList.find(doc => doc.value === Id)
        if (variety) {
            return this.$i18n.locale === 'bn' ? variety.text_bn : variety.text_en
        } else {
            return null
        }
    },
    getOfficeTypeList (orgId = null) {
        const officeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
        if (orgId) {
            return officeTypeList.filter(office => office.org_id === orgId).map(obj => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text }
                }
            })
        }
        return officeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getProductionSourceList (productionSourceType) {
      const productionSourceList = this.$store.state.SeedsFertilizer.commonObj.productionSourceList.filter(item => item.status === 1 && item.source_type_id === productionSourceType)
      return productionSourceList
    }
  }
}
</script>
